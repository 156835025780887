import React, { useState, useRef, useEffect } from 'react'
import { StyledFadeInSection } from './styles'

const FadeInSection = ({ children, ...rest }) => {
  const [isVisible, setVisible] = useState(false)
  const domRef = useRef()

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setVisible(entry.isIntersecting)
        }
      })
    })
    observer.observe(domRef.current)
    return () => observer.unobserve(domRef.current)
  }, [])

  return (
    <StyledFadeInSection isVisible={isVisible} ref={domRef} {...rest}>
      {children}
    </StyledFadeInSection>
  )
}

export default FadeInSection
