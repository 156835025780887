import styled from 'styled-components'

export const NoPhoto = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 50px 0;
`
