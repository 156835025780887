import React from 'react'
import { StyledImageInfo } from './styles'

const ImageInfo = ({ fields, ...rest }) => {
  const {
    exif: {
      gps: { latitude, longitude },
      meta: { dateTaken },
      raw: {
        image: { Model: model },
      },
    },
  } = fields

  return (
    <StyledImageInfo {...rest}>
      {dateTaken && (
        <span>
          {dateTaken}
          <br />
        </span>
      )}
      {model && <span>{model}</span>}
      {longitude && latitude && (
        <div>
          {' '}
          <span>{`lat: ${latitude.toFixed(2)}`}</span>
          <br />
          <span>{`lon: ${longitude.toFixed(2)}`}</span>
        </div>
      )}
    </StyledImageInfo>
  )
}

export default ImageInfo
