import { useStaticQuery, graphql } from 'gatsby'

const useGallery = () => {
  const {
    allDropboxNode: { nodes },
  } = useStaticQuery(graphql`
    query {
      allDropboxNode(sort: { order: DESC, fields: lastModified }) {
        nodes {
          id
          name
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_tracedSVG
                presentationWidth
                presentationHeight
                aspectRatio
              }
              fields {
                exif {
                  gps {
                    latitude
                    longitude
                  }
                  meta {
                    dateTaken(formatString: "YYYY-MM-DD")
                  }
                  raw {
                    image {
                      Model
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return nodes.map(
    ({
      id,
      name,
      localFile: {
        childImageSharp: { fluid, fields },
      },
    }) => ({
      id,
      name,
      fluid,
      fields,
    }),
  )
}

export default useGallery
