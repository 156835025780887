import React, { useEffect } from 'react'
import ArrowPrevSrc from '../../../static/icons/arrow_previous.svg'
import ArrowNextSrc from '../../../static/icons/arrow_next.svg'
import {
  Overlay, StyledImg, ArrowPrev, ArrowNext, Close,
} from './styles'

const Lightbox = ({
  fluid, onClose, goPrev, goNext,
}) => {
  const handleKeyDown = (e) => {
    const { keyCode } = e

    if (keyCode === 37) {
      goPrev()
    }

    if (keyCode === 39) {
      goNext()
    }

    if (keyCode === 27) {
      onClose()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  })

  return (
    <Overlay>
      <Close onClick={onClose} />
      <ArrowPrev onClick={goPrev}>
        <img src={ArrowPrevSrc} alt='' />
      </ArrowPrev>
      <ArrowNext onClick={goNext}>
        <img src={ArrowNextSrc} alt='' />
      </ArrowNext>
      <StyledImg fluid={fluid} objectFit='contain' />
    </Overlay>
  )
}

export default Lightbox
