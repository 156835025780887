import React from 'react'
import Layout from '../components/Layout/Layout'
import Intro from '../components/Intro/Intro'
import Gallery from '../components/Gallery/Gallery'

export default ({ data }) => (
  <Layout>
    <Intro />
    <Gallery data={data} />
  </Layout>
)
