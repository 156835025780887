import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'

export const Overlay = styled.div`
  z-index: 200;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
`

export const StyledImg = styled(Img)`
  width: 100%;
  height: 100%;
  max-width: ${props => `${props.fluid.presentationWidth}px`};
  max-height: ${props => `${props.fluid.presentationHeight}px`};
`

const Button = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
  &:active {
    background-color: rgba(0, 0, 0, 0.5);
  }
`

export const ArrowPrev = styled(Button)`
  position: absolute;
  z-index: 1;
  top: 50%;
  margin-top: -125px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 250px;

  img {
    width: 50px;
  }

  @media (max-width: 992px) {
    width: 45px;
    height: 150px;
    margin-top: -75px;

    img {
      width: 33px;
    }
  }
`

export const ArrowNext = styled(ArrowPrev)`
  right: 0;
  left: auto;
`

export const Close = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  width: 150px;
  height: 150px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 40px;
    display: block;
    background-color: #f9c806;
    width: 70px;
    height: 3px;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  @media (max-width: 992px) {
    width: 45px;
    height: 45px;

    &::before,
    &::after {
      left: -1px;
      width: 47px;
      height: 2px;
    }
  }
`
