import React, { useState, useEffect } from 'react'
import Img from 'gatsby-image'
// eslint-disable-next-line import/no-extraneous-dependencies
import { globalHistory as history, navigate } from '@reach/router'
import Lightbox from '../Lightbox/Lightbox'
import {
  Grid,
  Shot,
  StyledImageInfo,
  ImageWrapper,
  StyledInfoIcon,
} from './styles'
import InfoIcon from '../../../static/icons/info-icon.svg'

const Shots = ({ images }) => {
  const [current, setCurrent] = useState(-1)

  useEffect(() => {
    const {
      location: { hash },
    } = history

    if (hash) {
      const currentImg = images.findIndex(({ id }) => `#${id}` === hash)
      setCurrent(currentImg)
    }
  }, [images])

  const handleClick = (imgId) => {
    navigate(`#${imgId}`)
  }

  const onClose = () => {
    setCurrent(-1)
  }

  const goNext = () => {
    const next = (current + 1) % images.length
    navigate(`#${images[next].id}`)
  }

  const goPrev = () => {
    const prev = (current - 1 + images.length) % images.length
    navigate(`#${images[prev].id}`)
  }

  return (
    <>
      {current >= 0 && (
        <Lightbox
          fluid={images[current].fluid}
          onClose={onClose}
          goNext={goNext}
          goPrev={goPrev}
        />
      )}
      <Grid>
        {images.map(({
          id, name, fluid, fields,
        }) => {
          let fieldsFilled

          if (fields) {
            const {
              exif: {
                gps: { latitude, longitude },
                meta: { dateTaken },
                raw: {
                  image: { Model: model },
                },
              },
            } = fields

            fieldsFilled = latitude !== null
              || longitude !== null
              || dateTaken !== null
              || model !== null
          } else {
            fieldsFilled = false
          }
          return (
            <Shot key={id} onClick={() => handleClick(id)} id={id}>
              <ImageWrapper>
                {fieldsFilled && <StyledInfoIcon src={InfoIcon} />}
                <Img fluid={{ ...fluid, aspectRatio: 1 }} alt={name} />
                {fieldsFilled && <StyledImageInfo fields={fields} />}
              </ImageWrapper>
            </Shot>
          )
        })}
      </Grid>
    </>
  )
}

export default Shots
