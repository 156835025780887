import React from 'react'
import Container from '../common/Container/Container'
import Shots from '../Shots/Shots'
import { NoPhoto } from './styles'
import config from '../../../config'
import useGallery from '../../hooks/useGallery'

const Gallery = () => {
  const images = useGallery()

  if (!images || !images.length) {
    return <NoPhoto>{config.noPhotoMessage}</NoPhoto>
  }

  return (
    <Container>
      <Shots images={images} />
    </Container>
  )
}

export default Gallery
