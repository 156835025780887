import styled from 'styled-components'

export const StyledImageInfo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 1em;
  padding-left: 50px;
  font-size: 14px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5);
`
