import styled from 'styled-components'
import ImageInfo from '../ImageInfo/ImageInfo'
import FadeInSection from '../FadeInSection/FadeInSection'

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -14px;
  margin-right: -14px;
`

export const Shot = styled.div`
  box-sizing: border-box;
  width: calc(100% / 3);
  padding: 14px;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 50%;
  }
  @media (max-width: 596px) {
    width: 100%;
  }
`

export const ImageWrapper = styled(FadeInSection)`
  overflow: hidden;
`

export const StyledInfoIcon = styled.img`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 25px;
  height: 25px;
  z-index: 1;
  opacity: 0.3;
  transition: opacity 0.2s ease-in;

  ${Shot}:hover & {
    opacity: 1;
  }
`

export const StyledImageInfo = styled(ImageInfo)`
  transform: translateY(-100%);
  transition: transform 0.3s ease-in;

  ${StyledInfoIcon}:hover ~ & {
    transform: translateY(0);
  }
`
