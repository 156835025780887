import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Container from '../common/Container/Container'
import Smile from '../../../static/icons/smille.svg'

import {
  IntroContent, Avatar, Text, SmileIcon,
} from './styles'

const Intro = () => {
  const {
    allGoogleSpreadsheetSheet1: { nodes },
  } = useStaticQuery(graphql`
    query {
      allGoogleSpreadsheetSheet1 {
        nodes {
          avatar {
            childImageSharp {
              fixed(width: 150, height: 150, quality: 90) {
                ...GatsbyImageSharpFixed
              }
            }
            name
          }
        }
      }
    }
  `)

  const avatar = nodes && nodes[0] && nodes[0].avatar.childImageSharp.fixed

  return (
    <Container>
      <IntroContent>
        {avatar && <Avatar fixed={avatar} alt='avatar' />}
        <Text>
          Hi!
          <br />
          Welcome to my photoblog!
          <SmileIcon src={Smile} />
        </Text>
      </IntroContent>
    </Container>
  )
}

export default Intro
