import styled from 'styled-components'
import Img from 'gatsby-image'

export const IntroContent = styled.div`
  display: flex;
  align-items: center;
  padding: 30px 0;
  @media (max-width: 596px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`

export const Avatar = styled(Img)`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-right: 35px;
  @media (max-width: 596px) {
    margin-right: 0;
  }
`

export const SmileIcon = styled.img`
  border-radius: 50%;
  margin-left: 10px;
  transform: translateY(4px);
`

export const Text = styled.p`
  font-size: 25px;
  line-height: 29px;
  color: #4e4e4e;
  @media (max-width: 596px) {
    text-align: center;
  }
`
